.hacemos {
  height: 40.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F54450;
  box-shadow: inset 0em 3em 4em -4em; }
  .hacemos div.title {
    width: 29em;
    border-bottom-width: 0.3em;
    border-bottom-style: solid;
    border-bottom-color: #fff;
    margin-top: 3.2em;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: bold;
    font-size: 1em; }
    .hacemos div.title h1 {
      margin-bottom: 0.4em;
      color: #fff; }
  .hacemos section.hacemos-items {
    display: flex;
    margin-top: 3em; }
    .hacemos section.hacemos-items .hacemos-item {
      width: 24em;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .hacemos section.hacemos-items .hacemos-item .hacemos-item-number {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #121A27;
        width: 2.5em;
        height: 2.5em;
        font-size: 2.4em;
        color: #39DAF8;
        font-family: "Helvetica Neue", sans-serif;
        font-weight: bold; }
      .hacemos section.hacemos-items .hacemos-item .hacemos-item-title {
        margin-top: 2em;
        color: #fff;
        margin-bottom: 0.2em; }
      .hacemos section.hacemos-items .hacemos-item .hacemos-item-paragraph {
        font-family: "HelveticaNeue", sans-serif;
        font-size: 1.2em;
        font-weight: normal;
        font-style: normal;
        letter-spacing: 0;
        text-align: center;
        color: #fff;
        padding: 1.1em 1.2em;
        margin-top: 0; }

@media (max-width: 1178px) {
  .hacemos {
    height: 100%; }
  section.hacemos-items {
    flex-direction: column; } }
