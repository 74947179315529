.nosotros {
  height: 21.9em;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .nosotros div.nosotros-title {
    width: 29em;
    border-bottom-width: 0.3em;
    border-bottom-style: solid;
    border-bottom-color: #F54450;
    margin-top: 4em;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: bold;
    font-size: 1em; }
    .nosotros div.nosotros-title h1 {
      margin-bottom: 0.4em;
      font-weight: bold;
      font-size: 2em; }
  .nosotros p {
    font-family: "HelveticaNeue", sans-serif;
    font-size: 1.3em;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    color: #121a27;
    padding: 1.1em 20vw; }

@media (max-width: 968px) {
  .nosotros p {
    font-size: 1em; } }
