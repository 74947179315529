.landing {
  background-image: url("../../assets/mision@2x.jpg");
  display: flex;
  height: 57.2vw;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0em -3em 4em -4em;
  margin-top: 119px; }

.landing-rectangle {
  width: 60%;
  height: 16vw;
  background-color: #0573DC; }
  .landing-rectangle h1 {
    color: #fff;
    font-family: "Helvetica Neue";
    font-size: 4vw;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0.5em; }
  .landing-rectangle p {
    color: #fff;
    font-family: "Helvetica Neue";
    font-size: 1.8vw;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    padding: 0 2em;
    margin-top: 0.3em; }
