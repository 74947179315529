header {
  position: relative;
  background: #fff;
  width: 100%;
  height: 50px;
  z-index: 999;
  display: flex;
  justify-content: center; }
  header.scroll {
    position: fixed;
    display: flex; }
  header .navbar {
    padding: 0 2%; }
    header .navbar .navbar-brand {
      align-items: center; }
      header .navbar .navbar-brand div a img.logo {
        width: 21.8em;
        padding-top: 0.7em;
        margin-right: 5.6em; }
        header .navbar .navbar-brand div a img.logo img {
          width: 10em;
          height: 84px;
          padding: 0 20px;
          margin-top: 2px; }
    header .navbar.transparent {
      background: transparent; }
  header ul {
    flex-direction: row; }
    header ul > li {
      padding: 10px 10px; }

.navbar.is-primary {
  background-color: #F6724F;
  color: #fff; }

.navbar-burger {
  color: #000 !important; }

.navbar .navbar-item img {
  max-height: 2.75rem; }

.scroll .navbar .navbar-item img {
  max-height: 2rem; }

.navbar-center {
  width: 100%;
  display: flex;
  align-items: center; }

.scroll {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  margin-top: 0;
  height: auto;
  top: 0; }

ul.menu-custom {
  text-align: right;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center; }

header.scroll ul.menu-custom {
  padding: 0; }

header.scroll ul.menu-custom > li {
  padding: 5px 20px; }

ul.menu-custom > li {
  display: flex;
  padding: 0.7em 0.8em;
  font-size: 13pt;
  margin-top: 0 !important; }

ul.menu-custom > li > a {
  color: #000;
  display: inline-block;
  font-size: 17px;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 10px 0;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 600;
  text-decoration: none; }

ul.menu-custom.green-hover > li > a::after {
  content: '';
  display: block;
  width: 0;
  height: 5px;
  background: #F6724F;
  transition: width .3s linear; }

ul.menu-custom.green-hover > li > a:hover::after {
  width: auto;
  margin: auto; }

ul.menu-custom.green-hover > li.active > a::after {
  width: auto;
  margin: auto; }

.logo-dark {
  display: none; }

.logo-white {
  display: block; }

.scroll .logo-dark {
  display: block; }

.scroll .logo-white {
  display: none; }

.navbar-menu {
  background-color: transparent !important;
  height: 119px;
  align-items: center; }
  .navbar-menu.is-active {
    height: auto; }

@media (min-width: 1024px) {
  .navbar.is-primary {
    background-color: #fff;
    color: #404040; } }

header.scroll .logo {
  display: flex;
  height: auto;
  justify-content: center; }

@media (min-width: 1024px) {
  header {
    height: 119px; }
    header.scroll {
      height: 119px; }
      header.scroll ul.menu-custom {
        padding: 12px 0;
        position: relative;
        right: 0;
        flex-direction: row;
        justify-content: flex-end; }
        header.scroll ul.menu-custom > li {
          padding: 5px 8px; } }

@media (min-width: 1408px) {
  header.scroll ul.menu-custom > li {
    padding: 5px 15px; } }

@media (max-width: 686px) {
  header .navbar-center {
    justify-content: center; }
    header .navbar-center ul.menu-custom {
      flex-direction: column;
      justify-content: center; } }
