.somos {
  height: 40.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFBFF; }
  .somos div.title {
    width: 29em;
    border-bottom-width: 0.3em;
    border-bottom-style: solid;
    border-bottom-color: #F54450;
    margin-top: 2.2em;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: bold;
    font-size: 1em; }
    .somos div.title h1 {
      margin-bottom: 0.4em;
      color: #000; }
  .somos section.somos-items {
    display: flex;
    margin-top: 4.8em; }
    .somos section.somos-items .presidente-item {
      width: 31em;
      height: 24em;
      order: 2;
      background-image: url("../../assets/presidente@3x.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      border-radius: 1em;
      justify-content: flex-end; }
      .somos section.somos-items .presidente-item div {
        background-color: rgba(255, 255, 255, 0.8);
        width: 12em;
        height: 2.6em;
        font-size: 21px;
        padding-top: 0.4em;
        text-align: left;
        border-radius: 10px;
        margin-right: 0.6em;
        margin-top: 0.5em;
        padding-left: 0.4em; }
        .somos section.somos-items .presidente-item div h3 {
          font-size: 19px;
          margin: 0; }
    .somos section.somos-items .somos-item:nth-child(1) {
      order: 1; }
    .somos section.somos-items .somos-item:nth-child(2) {
      order: 3; }
    .somos section.somos-items .somos-item {
      width: 24em;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff; }
      .somos section.somos-items .somos-item .somos-item-number {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #121A27;
        width: 2.5em;
        height: 2.5em;
        font-size: 2.4em;
        color: #39DAF8;
        font-family: "Helvetica Neue", sans-serif;
        font-weight: bold; }
      .somos section.somos-items .somos-item .somos-item-title {
        margin-top: 2em;
        color: #000;
        margin-bottom: 0.2em;
        align-self: flex-start;
        font-family: "Helvetica Neue", sans-serif;
        font-weight: bold;
        font-size: 21px;
        padding-left: 3em; }
      .somos section.somos-items .somos-item .somos-item-paragraph {
        font-family: "HelveticaNeue", sans-serif;
        font-size: 1.2em;
        font-weight: normal;
        font-style: normal;
        letter-spacing: 0;
        color: #000;
        padding: 1.1em 3.4em;
        margin-top: 0;
        text-align: left; }

@media (max-width: 1178px) {
  .somos {
    height: 100%; }
  section.somos-items {
    flex-direction: column; } }
