div.footer {
  height: 21.6em;
  background-color: #121a27; }
  div.footer nav div div.navbar-center {
    flex-direction: column; }
    div.footer nav div div.navbar-center div.copyright {
      font-size: 0.9em;
      color: #d9eff7;
      font-weight: 300;
      font-family: "Helvetica Neue", sans-serif; }
    div.footer nav div div.navbar-center img {
      width: 32em;
      height: 4em;
      margin-top: 2.9em; }
    div.footer nav div div.navbar-center ul {
      margin-top: 0.4em; }
      div.footer nav div div.navbar-center ul li {
        padding: 0.8em 1.1em; }
        div.footer nav div div.navbar-center ul li a {
          color: #fff; }
  div.footer ul.custom-menu {
    color: #fff;
    flex-direction: column; }

@media (max-width: 662px) {
  div.footer {
    height: 33.6em; }
    div.footer nav div div.navbar-center img {
      width: 20em;
      height: 3em; }
    div.footer nav div div.navbar-center ul {
      flex-direction: column; } }
