.contacto {
  height: 36.7em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6724f; }
  .contacto section form div {
    display: flex; }
    .contacto section form div section {
      display: flex;
      flex-direction: column;
      width: 60%;
      align-items: flex-start;
      font-family: "Helvetica Neue", sans-serif;
      color: #fff; }
      .contacto section form div section label {
        font-size: 1.3em; }
      .contacto section form div section input {
        width: 85%;
        background-color: #f6724f;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: solid 0.1em #fff;
        font-size: 1.3em;
        color: #fff; }
      .contacto section form div section textarea {
        resize: none;
        margin-top: 1.5em;
        border-radius: 0.5em;
        border-color: #d9eff7;
        background-color: #fff;
        padding: 1em;
        font-size: 1em;
        font-family: "Helvetica Neue", sans-serif; }
  .contacto section form div:nth-child(2) {
    margin-top: 0.5em; }
  .contacto section form button {
    margin-top: 1.7em;
    font-size: 1em;
    font-family: "Helvetica Neue", sans-serif;
    border: solid 3px #d9eff7;
    border-radius: 0.3em;
    width: 6em;
    height: 4em;
    background-color: #fafbff;
    cursor: pointer;
    padding-top: 0.3em; }
  .contacto section form button:hover {
    background-color: #d9eff7; }
  .contacto div.contacto-title {
    width: 29em;
    border-bottom-width: 0.3em;
    border-bottom-style: solid;
    border-bottom-color: #fff;
    margin-top: 2.2em;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: bold;
    font-size: 1em; }
    .contacto div.contacto-title h1 {
      margin-bottom: 0.4em;
      color: #fff;
      font-size: 2em; }
  .contacto section {
    display: flex;
    width: 75em;
    margin-top: 2.3em; }
    .contacto section div.info-contacto {
      width: 20em;
      height: 22em;
      margin-left: 4em; }
      .contacto section div.info-contacto div.item-contacto {
        display: flex;
        align-items: center;
        margin-bottom: 1em; }
        .contacto section div.info-contacto div.item-contacto .icon-container {
          width: 3.1em;
          height: 2.5em; }
          .contacto section div.info-contacto div.item-contacto .icon-container img {
            width: 60%; }
        .contacto section div.info-contacto div.item-contacto .details-container {
          text-align: left;
          color: #fff;
          font-family: "Helvetica Neue", sans-serif;
          font-size: 1em;
          font-style: normal;
          letter-spacing: 0;
          width: 85%; }
          .contacto section div.info-contacto div.item-contacto .details-container .item-contacto-title {
            font-weight: normal;
            font-size: 1.7em;
            padding-left: 1.2em;
            margin: 0;
            padding-top: 0.7em; }
          .contacto section div.info-contacto div.item-contacto .details-container .item-contacto-detail {
            font-size: 1.2em;
            padding-left: 1.7em;
            font-weight: 300;
            margin: 0; }
    .contacto section form.formulario-contacto {
      width: 100%;
      height: 22em;
      margin-left: 4em;
      display: flex;
      flex-direction: column; }

@media (max-width: 1178px) {
  .contacto {
    height: 100%; }
    .contacto section {
      flex-direction: column;
      width: 80%; }
      .contacto section div.info-contacto {
        display: flex; }
  form.formulario-contacto button {
    margin-bottom: 2em; } }

@media (max-width: 978px) {
  .contacto {
    height: 100%; }
    .contacto section {
      flex-direction: column;
      width: 80%;
      align-items: center; }
      .contacto section div.info-contacto {
        display: block; }
      .contacto section form.formulario-contacto {
        height: 24em; }
        .contacto section form.formulario-contacto div section textarea {
          width: 65vw; }
        .contacto section form.formulario-contacto button {
          margin-bottom: 2em; } }
